"use client";

import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { cn, HeroIcon } from "@/lib/utils";
import { LucideIcon } from "lucide-react";
import Image from "next/image";
import { ReactNode } from "react";
import { IconType } from "react-icons";

interface PlatformCardProps {
    onClick?: () => void;
    thumbnail?: {
        src?: string;
    };
    thumbnailBadge?: {
        text: string | ReactNode;
    };
    text?: {
        title?: string | ReactNode | null;
        subtitle?: string | ReactNode | null;
        icon?: LucideIcon | HeroIcon | IconType;
    };
    options?: { component: ReactNode; positionClassName?: string };
    selected?: boolean;
}

export default function PlatformCard({
    onClick,
    thumbnail,
    thumbnailBadge,
    text,
    options,
    selected,
}: PlatformCardProps) {
    return (
        <Card
            onClick={onClick}
            className={cn(
                "relative cursor-pointer hover:bg-card/70 transition shadow-md flex flex-col",
                selected && "border-2 border-blue-500 bg-blue-50"
            )}
        >
            {options && (
                <div
                    className={cn(
                        "absolute",
                        options.positionClassName || "top-2 left-2"
                    )}
                >
                    {options.component}
                </div>
            )}

            {thumbnail && (
                <div className="relative aspect-video rounded-t-md border-b">
                    <Image
                        fill
                        src={thumbnail.src || "/images/image-placeholder.png"}
                        alt="Thumbnail"
                        className="rounded-t-md object-contain"
                    />
                    {thumbnailBadge && (
                        <Badge className="absolute bottom-2 left-2 font-normal px-2">
                            {thumbnailBadge.text}
                        </Badge>
                    )}
                </div>
            )}

            {text && (
                <footer className="flex flex-row gap-2 px-3 py-4">
                    {text.icon && (
                        <text.icon className="mt-[3px] size-[18px] text-primary shrink-0" />
                    )}
                    <div
                        className={cn(
                            "flex flex-col truncate",
                            text.icon ? "gap-1.5" : "gap-2"
                        )}
                    >
                        {text.title && (
                            <h3 className="font-medium truncate">
                                {text.title}
                            </h3>
                        )}
                        {text.subtitle && (
                            <p className="text-xs text-muted-foreground">
                                {text.subtitle}
                            </p>
                        )}
                    </div>
                </footer>
            )}
        </Card>
    );
}
