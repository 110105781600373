"use client";

import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { T } from "@tolgee/react";

export default function ExportCollectionDropdownMenuItem({
    collectionId,
}: {
    collectionId: string;
}) {
    const exportCollection = async () => {
        const res = await client.api.storage.collection[
            ":collectionId"
        ].export.$get({
            param: {
                collectionId,
            },
        });

        if (!res.ok) {
            throw new Error("Failed to export collection");
        }

        const { data } = await res.json();

        // Map headers from English to Hebrew
        const headers = {
            id: "id",
            name: "videoName",
            embedUrl: "embedLink",
        };

        // Convert data to CSV format
        const csvContent = [
            Object.values(headers).join(","),
            ...data.map((item) =>
                [item.id, item.name ?? "", item.embedUrl]
                    .map((value) => `"${value}"`)
                    .join(",")
            ),
        ].join("\n");

        // Create a Blob and trigger download
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "collection.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <DropdownMenuItem
            onClick={(e) => {
                e.stopPropagation();
                toast.promise(
                    exportCollection(),
                    {
                        loading: "מכין את הקובץ...",
                        success: "הקובץ ירד בהצלחה!",
                        error: "קרתה שגיאה",
                    },
                    {
                        loading: {
                            keyName: "toast.loading.export-collection",
                        },
                        success: {
                            keyName: "toast.success.export-collection",
                        },
                        error: {
                            keyName: "toast.error.export-collection",
                        },
                    }
                );
            }}
        >
            <T
                keyName="storage.collections-grid.item.options.export"
                defaultValue="ייצוא"
            />
        </DropdownMenuItem>
    );
}
