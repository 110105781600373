import { z } from "zod";

export type TabParam =
    | ""
    | "folders"
    | "files"
    | "analytics"
    | "syllabuses"
    | "settings";
export type Tab = {
    name: string;
    keyName: string;
    param: TabParam;
    current: boolean;
};

export const defaultTabs: Tab[] = [
    {
        name: "כללי",
        keyName: "storage.tabs.overview",
        param: "",
        current: true,
    },
    {
        name: "תיקיות",
        keyName: "storage.tabs.folders",
        param: "folders",
        current: false,
    },
    {
        name: "קבצים",
        keyName: "storage.tabs.files",
        param: "files",
        current: false,
    },
    {
        name: "אנליטיקות",
        keyName: "storage.tabs.analytics",
        param: "analytics",
        current: false,
    },
    {
        name: "סילאבוסים",
        keyName: "storage.tabs.syllabuses",
        param: "syllabuses",
        current: false,
    },
    {
        name: "הגדרות",
        keyName: "storage.tabs.settings",
        param: "settings",
        current: false,
    },
];

export type StorageSortBy = "createdAt" | "name";
export const zStorageSortBy = z.enum(["createdAt", "name"]);
export type StorageOrderBy = "asc" | "desc";
export const zStorageOrderBy = z.enum(["asc", "desc"]);
export type StorageFilterByType = "all" | "videos" | "presentations";
export const zStorageFilterByType = z.enum(["all", "videos", "presentations"]);

type Option<T> = {
    value: T;
    keyName: string;
    defaultValue: string;
};

export const storageOrderByOptions: Option<StorageOrderBy>[] = [
    {
        value: "desc",
        keyName: "storage.order-options.desc",
        defaultValue: "יורד",
    },
    {
        value: "asc",
        keyName: "storage.order-options.asc",
        defaultValue: "עולה",
    },
];

export const storageSortByOptions: Option<StorageSortBy>[] = [
    {
        value: "createdAt",
        keyName: "storage.sort-options.created-at",
        defaultValue: "נוצר בתאריך",
    },
    {
        value: "name",
        keyName: "storage.sort-options.name",
        defaultValue: "שם",
    },
];

export const storageFilterByTypeOptions: Option<StorageFilterByType>[] = [
    {
        value: "all",
        keyName: "storage.filter-by-type-options.all",
        defaultValue: "הכל",
    },
    {
        value: "videos",
        keyName: "storage.filter-by-type-options.videos",
        defaultValue: "סרטונים",
    },
    {
        value: "presentations",
        keyName: "storage.filter-by-type-options.presentations",
        defaultValue: "מצגות",
    },
];
