"use client";

import { Button, buttonVariants } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { FolderPlus, LoaderCircle } from "lucide-react";
import { useState } from "react";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { T } from "@tolgee/react";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@/lib/utils";

export default function CreateCollectionButton({
    variant = "outline",
}: {
    variant?: "default" | "outline";
}) {
    const [open, setOpen] = useState(false);

    const [isPending, setIsPending] = useState(false);

    const queryClient = useQueryClient();

    const submitHandler = async (e: any) => {
        e.preventDefault();

        setIsPending(true);

        const name = e.target.collectionName.value;

        const res = await client.api.storage.collection.create.$post({
            json: {
                name,
            },
        });

        if (!res.ok) {
            const error = await res.text();
            console.log(error);
            toast.error("אירעה שגיאה בנסיון יצירת התיקייה", {
                keyName: "toast.error.default",
            });
        } else {
            const data = await res.json();
            console.log(data);

            queryClient.invalidateQueries({
                queryKey: ["get-storage-collections"],
            });
            toast.success("התיקייה נוצרה בהצלחה!", {
                keyName: "toast.success.create-collection",
            });
            setOpen(false);
            setIsPending(false);
        }
    };

    return (
        <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
            <DialogTrigger>
                <div
                    className={cn(
                        buttonVariants({ variant, size: "sm" }),
                        "flex flex-row w-fit gap-2"
                    )}
                >
                    <FolderPlus className="size-4" />
                    <T
                        keyName="storage.create-library-btn"
                        defaultValue="תיקייה חדשה"
                    />
                </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[380px]">
                <DialogHeader>
                    <DialogTitle>
                        <T
                            keyName="storage.create-collection-modal.header.title"
                            defaultValue="תיקייה חדשה"
                        />
                    </DialogTitle>
                    <DialogDescription>
                        <T
                            keyName="storage.create-collection-modal.header.description"
                            defaultValue="בחרו שם לתיקייה ולחצו יצירה."
                        />
                    </DialogDescription>
                </DialogHeader>
                <form onSubmit={submitHandler}>
                    <div className="py-4">
                        <div className="flex flex-col gap-2">
                            <Label
                                htmlFor="collectionName"
                                className="text-right"
                            >
                                <T
                                    keyName="storage.create-collection-modal.collection-name-input.label"
                                    defaultValue="שם התיקייה"
                                />
                            </Label>
                            <Input
                                id="collectionName"
                                defaultValue=""
                                required
                            />
                        </div>
                    </div>
                    <DialogFooter className="mt-4">
                        <Button
                            disabled={isPending}
                            type="submit"
                            className="flex w-full justify-center items-center"
                        >
                            {isPending ? (
                                <LoaderCircle className="size-4 text-white animate-spin" />
                            ) : (
                                <T
                                    keyName="storage.create-collection-modal.submit-btn"
                                    defaultValue="יצירה"
                                />
                            )}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
}
